import React,{useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {
  Link
  } from 'react-router-dom'
  import { Modal } from "react-bootstrap";

  import { useDispatch } from 'react-redux';
  import {CheckCircleTwoTone } from '@ant-design/icons';


const  ConsentSuccess = (props)=> {

    const navigate = useNavigate();
    const dispatch = useDispatch();


  const click = () =>{
    // window.open(`http://localhost:3000/onb3`, '_blank').focus();
    // window.close()
    //navigate('/onb3')
  }


  const handleBackButtonClick = () => {
       window.close();
  };

  // useEffect(() => {
  //  setTimeout(click, 5000);
   
  // }, []);



  return (
    <div className="App App-header ">

          <Modal.Header >
            <div className="d-flex align-items-center">
                  <div className="row text-center">
                    <img  src="/jioLogo.PNG"  className="img-responsive p-2 align-self-center" style={{width: '70px',height: '70px'}} />
                  </div>
                  <Modal.Title className="pr-3 pl-3" style={{color:"black"}}>Consent Manager</Modal.Title>
            </div>
                
          </Modal.Header>

            <Modal.Body  >
              <div className="p-3 m-3" style={{marginVertical:50}}>
              <CheckCircleTwoTone className="m-2"  style={{fontSize:'50px',color: 'black'}} />
                  <h1  style={{fontSize:'20px',color:'black'}}>Congratulations! !!</h1>

                  <h1  style={{fontSize:'14px',color:'black'}}>Your data use consent has been successfully captured.</h1>
                  <hr></hr>
                  {/* <h1  style={{fontSize:'14px',color:'black'}}>Transferring you back to JioSaavn !!</h1> */}
                  <button type='button' class="btn btn-danger" onClick={handleBackButtonClick}>Close Window</button>
              </div>
            </Modal.Body >

    </div>
  );
}

export default ConsentSuccess;
