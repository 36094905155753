import React,{useState,useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom'
  import { useDispatch,useSelector } from 'react-redux';
  import {CheckCircleTwoTone } from '@ant-design/icons';
  import Card from 'react-bootstrap/Card';
  import Table from 'react-bootstrap/Table';
  import { Modal } from "react-bootstrap";
  import moment from "moment";
  import {updateStatus,consentByCID, revokeConsentCall} from '../../store/actions/CommonAction'
  import { DataGrid, GridToolbar  } from '@mui/x-data-grid'
  import Carousel from 'react-bootstrap/Carousel';


const  Tab1 = ()=> {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const[showModal,setShowModal] = useState(false);
  const[item,setItem] = useState('');
  const[val,setVal] = useState('');

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  
  const ActiveList = useSelector(state => state.common.ActiveList)
  const AllConsent = useSelector(state => state.common.AllConsent)
  

  var list = [];

  // const click = () =>{
  //     navigate("/onb5")
  // }

  const toggleModal = () =>{
    setShowModal(!showModal)
  }

  const viewDetail = (item) =>{
    setItem(item)
    setShowModal(!showModal)
  }



  const columns = [
    {
      headerName: 'Data Used By',
      field:'dataConsumer',
      width: 150,
      filter: true,
      className:'gridHead'
    },
    {
      headerName:'Data Types Used',
      field:'consentTypes',
      width: 250
    },
    {
      headerName: 'Purpose of Data Used',
      field:'purpose',
      width: 130,
      renderCell: (params) => params.value.text ,
    },
    {
      headerName: 'Consent Duration',
      field:'consentValidity',
      width: 130,
      renderCell:(params) => params.value.value+" "+params.value.unit
    },
    {
      headerName: 'Consent Creation Date',
      field:'createTimestamp',
      width: 200,
      type:'date',
      filter:true,
      valueFormatter: (params) => {
        return `${new Date(params.value)}`;
      },
    },
    {
      headerName: 'Consent Manager',
      valueGetter: () => 'Jio Consent Manager',
      width: 200,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <button onClick={()=>viewDetail(params.row)} className="btn btn-info m-0" style={{backgroundColor:'#660209'}}>View Details</button>
      ),
    },
];

  const revoke = async(item) => {
    // await dispatch(updateStatus(id))
    // await dispatch(consentByCID())

    let response = "";
    try{
      console.log("Starting Revoke Consent");
  
      //alert("in tab "+item);
      const result = await confirm("Are you sure? you want to revoke the consent");
      if (result) {
        response = await dispatch(revokeConsentCall(item))
       // alert("got response : "+response);
        if(response == "200"){
          await toast.success("Consent is Revoked Successfully!");
        }else{
          await toast.error("Consent could not be Revoked!");
        }
      } 
  
       
    } catch (e) {
      console.log("Revoke Consent Operation Failed ", JSON.stringify(e), e);
    }


    await setShowModal(!showModal)
  }

  const filterData= (selectedDate) => {
      //alert(selectedDate);
      //alert("ActiveList : "+JSON.stringify(ActiveList));

      var resultData = {};

      var ActiveList = [];

      for(var activeConsent of ActiveList){
        var resultData = activeConsent.filter(function (a) {
          var creationDate = a.createTimestamp || {};
  
          creationDate = Object.keys(creationDate);
       
          const creationDateMatchExists = creationDate.some(function(dateStr) {
              var date = new Date(dateStr);
              return date == selectedDate
          });
          return creationDateMatchExists;
          
      });

      console.log("result "+resultData);
      }

      
    
  }

  const revokenotification = async () => {
    await toast.info("Revoke Feature Coming Soon..!");
}

  // const call =()=>{
  //   fetch('https://api.github.com/users').then((response)=>response.json()).
  //   then(res=>{
  //     setVal(res)
  //     console.log("user resp",JSON.stringify(res))
  //   })
  // }

  // useEffect(() => {
  //  call()
  // }, []);



  const CustomToolbar = ({ children }) => {
  // Remove the export button from the children array
  const filteredChildren = children.filter(child => {
    // Assuming the export button has a specific key, e.g., 'export', or it could be an icon, e.g., <SaveAltIcon />
    return child.type !== 'button' || child.props['aria-label'] !== 'Export';
  });

  return <GridToolbarContainer>{filteredChildren}</GridToolbarContainer>;
};



  return (
      <div className="App-bg">


<Carousel activeIndex={index} onSelect={handleSelect} interval={null} data-bs-theme="dark">
          
          {AllConsent && AllConsent.length > 0 && 
            AllConsent.map((item, key) => (
                  <Carousel.Item>
                        <div class="container-fluid consent-card" id={key}>
                        <table class="table">
                          <tr>
                            <th>Data Used By</th>
                            <td>{item.dataConsumer}</td>
                          </tr>
                          <tr>
                            <th>Data Types Used</th>
                            <td>
                                  
                                        {
                                          item.consentTypes.map((ele,key)=> (
                                          <React.Fragment key={key}>{ele}{key ===  item.consentTypes.length - 1? '' : ', '}</React.Fragment> 
                                          ))}
                                    </td>
                          </tr>
                          <tr>
                            <th>Purpose of Data Used</th>
                            <td>{item.purpose.text}</td>
                          </tr>

                          <tr>
                            <th>Consent Duration</th>
                            <td>{item.consentValidity.value} {item.consentValidity.unit}</td>
                          </tr>

                          <tr>
                            <th>Consent Creation Date</th>
                            <td>{moment(item.createTimestamp).format("DD-MM-YYYY hh:mm:ss")}</td>
                          </tr>

                          <tr>
                            <th>Consent Status</th>
                            <td>
                              {item.status && item.status == "ACTIVE" &&
                                <font style={{fontWeight:'bold', color:'green'}}>ACTIVE</font>
                              }
                               {item.status && item.status == "INACTIVE" &&
                                <font style={{fontWeight:'bold', color:'red'}}>REVOKED</font>
                              }
                              </td>
                          </tr>

                          </table>
                          <button  type="button" onClick={()=>viewDetail(item)} className="btn btn-view">View Details</button>

                      </div>
                  </Carousel.Item>
            ))
          }
          
          </Carousel>
       


        {/* <DataGrid
          rows={ActiveList}
          columns={columns}
          getRowId={(row) => row.srNo + row.consentId}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          components={{
            Toolbar: GridToolbar,
          }}
  
 
                className='table dashboard-table dashboard-card'
                // onCellClick={()=>viewDetail(row)}
                
              /> */}

     
            
            {/* <table className="table table-sm borderless table-striped" >
              {
                ActiveList && ActiveList.length !== 0 &&
                <thead className="text-left" style={{fontSize:'14px', color:'#660209'}}>
                  <tr>
                    <th scope="col">Data Used By</th>
                    <th scope="col">Data Types Used </th>
                    <th scope="col">Purpose of Data Used</th>
                    <th scope="col">Consent Duration</th>
                    <th scope="col">Consent Creation Date</th>
                    <th scope="col">Consent Manager</th>
                  </tr>
                </thead>
              }
            <tbody>{
              ActiveList.map((item, key) => (
                  <React.Fragment key={key}>

                            <tr>
                              <td>{item.dataConsumer}</td>
                              <td>
                             
                                  {
                                    item.consentTypes.map((ele,key)=> (
                                    <React.Fragment key={key}>{ele}{key ===  item.consentTypes.length - 1? '' : ', '}</React.Fragment> 
                                    ))}
                              </td>
                              <td >{item.purpose.text}</td>
                              <td >{item.consentValidity.value} {item.consentValidity.unit}</td>
                              <td >{moment(item.createTimestamp).format("DD-MM-YYYY hh:mm:ss")}</td>
                              <td >Jio Consent Manager</td>
                            
                              <td><button  type="button" onClick={()=>viewDetail(item)} className="btn btn-info m-0" style={{backgroundColor:'#660209'}}>View Details</button></td>
                              
                            </tr>

                  </React.Fragment>
                  
              ))
            }
            </tbody>
            
        </table>  */}
            {/* <button  type="button" onClick={click} className="btn btn-info m-4" style={{backgroundColor:'#660209'}}>Take me back to JioSaavn</button> */}

            {
              item &&
              <Modal  show={showModal} onHide={()=>setShowModal(!showModal)}
                        centered class="close App" data-dismiss="modal"
                      >
                  <Modal.Header className="App" >
                      <Modal.Title  >Consent Details</Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="App" >
                      <Card.Body >
                      <div className="table-responsive-sm text-center">
                     
                        <table  className="table-sm table-responsive d-flex justify-content-around">
                          
                          <tbody className="p-0 m-0">
                          <tr>
                              <td>Consent ID</td>
                              <td>:</td>
                              <td>{item.consentId}</td>
                            </tr>
                            <tr>
                              <td>Data Consumer</td>
                              <td>:</td>
                              {/* <td>JPL</td> */}
                              {/* <td>{item.dataConsumer }</td> */}
                              <td>
                                {
                                  item.dataConsumer.map((ele,key)=> (
                                  <React.Fragment key={key}>{ele}{key ===  item.dataConsumer.length - 1? '' : ', '}</React.Fragment> 
                                ))}
                              </td>
                            </tr>
                            <tr>
                            <td>Data Provider</td>
                              <td>:</td>
                              {/* <td>JIOSAAVN</td> */}
                              <td>{item.dataProvider }</td>
                            </tr>
                            <tr>
                            <td>Purpose</td>
                              <td>:</td>
                              {/* <td>{item.purpose}</td> */}
                              <td>
                                {
                                  item.purpose.map((ele,key)=> (
                                  <React.Fragment key={key}>{ele}{key ===  item.purpose.length - 1? '' : ', '}</React.Fragment> 
                                ))}
                              </td>
                            </tr>
                            <tr>
                            <td>Data Fields</td>
                              <td>:</td>
                              <td>
                                {
                                  item.consentTypes.map((ele,key)=> (
                                  <React.Fragment key={key}>{ele}{key ===  item.consentTypes.length - 1? '' : ', '}</React.Fragment> 
                                ))}
                              </td>
                            </tr>
                            <tr>
                            <td>Status</td>
                              <td>:</td>
                              <td>{item.status}</td>
                            </tr>
                            {/* <tr>
                              <td>From</td>
                              <td>:</td>
                              <td>{moment(item.fiDataRange.from).format("DD-MM-YYYY HH:mm")}</td>
                            </tr>
                            <tr>
                              <td>To</td>
                              <td>:</td>
                              <td>{moment(item.fiDataRange.to).format("DD-MM-YYYY HH:mm")}</td>
                            </tr> */}
                            <tr>
                              <td>Data Life</td>
                              <td>:</td>
                              <td>{item.dataLife.value} {item.dataLife.unit} </td>
                            </tr>
                            <tr>
                              <td>Frequency</td>
                              <td>:</td>
                              <td>{item.frequency.value} {item.frequency.unit}</td>
                            </tr>
                            <tr>
                              <td>Fetch type</td>
                              <td>:</td>
                              <td>{item.fetchType}</td>
                            </tr>
                            <tr>
                              <td>Start Date & Time</td>
                              <td>:</td>
                              <td>{moment(item.consentStart).format("DD-MM-YYYY HH:mm")}</td>
                            </tr>
                            <tr>
                              <td>End Date & Time</td>
                              <td>:</td>
                              <td>{moment(item.consentExpiry).format("DD-MM-YYYY HH:mm")}</td>
                            </tr>
                          </tbody>
                        </table>
                        
                        </div>
                    </Card.Body>
                  </Modal.Body>

                  <div className="text-center App p-4">
                    <button type="button" onClick={()=>revoke(item.consentId)} className="btn btn-outline-danger m-2 p-2">Revoke</button>
                    {/* <button type="button" onClick={revokenotification} className="btn btn-outline-danger m-2 p-2">Revoke</button> */}
                    <button type="button" onClick={toggleModal} className="btn btn-outline-secondary m-2 p-2">Close</button>
                  </div>

              </Modal>
            }

            <ToastContainer />
        
    </div>
  );
}

export default Tab1;

