import React,{useState} from 'react'
import { Modal } from "react-bootstrap";
import { makeAPICall } from '../../utils/ApiCall';
import config from '../../utils/config';
import { v4 as uuidv4 } from 'uuid';

import { useNavigate,useParams, useLocation } from "react-router-dom";

const Onboard3 = (props) => {
    const navigate = useNavigate();


return(
    <div >
    
    <img src="/img3.PNG" style={{width: '100%',height: '100%'}} 
     onClick={()=>{navigate('/onb4')}} />
    
        
    </div>
    
);
    
}

export default Onboard3;