import axios from "axios";
import {store} from '../configureStore';


export const makeAPICall = async (url,method,data,headers) => {
    try{
        let storedData = store.getState().common
    console.log('val inside apicall ',storedData)
    
        let  clientId = storedData.clientId
        let  clientSecret = storedData.clientSecret
        let  access_token = storedData.access_token
        //alert('INSIDE MAKE API CALL-->'+JSON.stringify(access_token));
        // if (url !== 'http://10.168.24.4:9006/oauth2/v2.0/token'){
        //     headers['clientId']= clientId
        //     headers['clientSecret']= clientSecret
        //     headers['Authorization']= `Bearer ${access_token}`
        // }

        if (url !== 'https://rpapi.consent.jiolabs.com/oauth2/v2.0/token' &&
        url !== 'https://rpapi.consent.jio.com/oauth2/v2.0/token' &&
        url !== 'http://10.173.152.4:9006/oauth2/v2.0/token' &&
        url !== 'http://10.173.184.4:9017/v1.4/consent/token' && 
        url !== 'https://api-dev.dscoe.jiolabs.com/generatetoken/v1.4/consent/token'
    ){
            //alert('INSIDE IF'+JSON.stringify(access_token));
            headers['clientId']= clientId
            headers['clientSecret']= clientSecret
            headers['Authorization']= `Bearer ${access_token}`
        }
    

        headers['Content-Type'] = 'application/json'
        headers['txnId']= "92929bc1-a28f-5d68-91af-44"
        headers['SubscriptionKey']= process.env.REACT_APP_SUBSCRIPTION_KEY
        headers['Ocp-Apim-Subscription-Key']= process.env.REACT_APP_SUBSCRIPTION_KEY
        let apiObjAxios = {url,method}
        //alert('Headers'+JSON.stringify(headers));
        apiObjAxios.headers=headers
        if(method==='POST' || method==='PUT'){
            apiObjAxios.data = data
        }

        // console.log(`val of Headers ${JSON.stringify(headers)}`)
     
        console.log(`HTTP request from makeAPICall ${JSON.stringify(apiObjAxios)}`);
        return new Promise ((resolve,reject)=>{
        axios(apiObjAxios)
            .then(async response => {
                // console.log(`resp from HTTP Call  ${(response)}`)
                resolve(response)
            }).catch(err => {
                console.log(`Error message HTTP Call  ${JSON.stringify(err)}`)
                reject(err)
            }) 
        })
    }
    catch(err){
        console.log(`Error message ${err}`)
        throw new Error('something went wrong')
    }
    }


 export const callAPI = async(url) => {
    return new Promise ((resolve,reject)=>{

    // axios({
    //     method: 'get',
    //     url: url,
    //     data: '',
    //     config: { headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json' ,
    //         "X-Source-Id" : "NUZsTFk2REhjYTljMG5QY1AyX0hoV21DWEUwYTozVjNSZlRTeUtTbEFLX1ZEUDNOeDZGNmxXNW9h",
    //         "X-Signature" : "TUhud1NmbUk1YnRvZVoyWWZiMllCZlQ3cGZNYTpVY01QWWZHdFNFQjF1ZjRYYXlIcFlxRU13VFVh",
    //         "txnid":"TXN12345jdidkdkdkdkdkdkdkd"
    //       }}
    //     })
    let headers = {
        'Accept': 'application/json',
                'Content-Type': 'application/json' ,
                "X-Source-Id" : "NUZsTFk2REhjYTljMG5QY1AyX0hoV21DWEUwYTozVjNSZlRTeUtTbEFLX1ZEUDNOeDZGNmxXNW9h",
                "X-Signature" : "TUhud1NmbUk1YnRvZVoyWWZiMllCZlQ3cGZNYTpVY01QWWZHdFNFQjF1ZjRYYXlIcFlxRU13VFVh",
                "txnid":"TXN12345jdidkdkdkdkdkdkdkd"
    }
        axios.get(url,{headers})
          .then((response) => {
              resolve(response)
              console.log(response)}) 
          .catch(error => {
              reject(error)
              console.log( 'the error has occured: ' + error) })
      })
    }



    export const deleteAPICall = async (url,data,headers) => {
        try{
            console.log(`Request ${JSON.stringify(data)}`)
            headers['crypto-mode'] =false
            let apiObjAxios = {url}
            apiObjAxios.headers=headers
            console.log(`HTTP request from deleteAPICall ${JSON.stringify(apiObjAxios)}`);
            return new Promise ((resolve,reject)=>{
            axios.delete(url,{data,headers})
                .then(async response => {
                    console.log(`Response from deleteAPICall with data ${JSON.stringify(response.data)}`);
                    resolve(response.data)
                }).catch(err => {
                    console.log(`Error message HTTP Call APICall Error Response ${JSON.stringify(err?.response)}`)
                    reject(err?.response)
                }) 
            })
        }
        catch(err){
            console.log(`Error message ${err}`)
            throw new Error(`something went wrong`)
        }
    }
    
    
    