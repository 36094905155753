import React,{useState} from 'react'
import { Modal } from "react-bootstrap";
import { makeAPICall } from '../../utils/ApiCall';
import config from '../../utils/config';
import { v4 as uuidv4 } from 'uuid';

import { useNavigate,useParams, useLocation } from "react-router-dom";

const Onboard7 = (props) => {

    const navigate = useNavigate();
    
    let data = 'data=ewoJInZlciI6ICIxLjEuMyIsCgkidGltZXN0YW1wIjogIjIwMjEtMDMtMTFUMTI6MDM6MTUuOTM2WiIsCgkidHhuaWQiOiAiODdiNDRmODAtYmIxNS00ZDY3LWEwNDMtMGVkZjZiZmVkYmI5IiwKCSJDb25zZW50RGV0YWlsIjogewoJCSJjb25zZW50U3RhcnQiOiAiMjAyMS0wMy0xMVQxMjowMzoyNC41MDFaIiwKCQkiY29uc2VudEV4cGlyeSI6ICIyMDIyLTAzLTEwVDEyOjAzOjI0LjUwMVoiLAoJCSJjb25zZW50TW9kZSI6ICJTVE9SRSIsCgkJImZldGNoVHlwZSI6ICJQRVJJT0RJQyIsCgkJImF1dGhNb2RlIjogIjAiLAoJCSJjb25zZW50VHlwZXMiOiBbCgkJCSJQcm9maWxlIiwKCQkJIlVzYWdlIiwKCQkJIlByZWZlcmVuY2VzIgoJCV0sCgkJImRhdGFUeXBlcyI6IFsiSklPU0FBVk4iXSwKCQkiRGF0YUNvbnN1bWVyIjogewoJCQkiaWQiOiAiQ0NBRDAxIiwKCQkJInR5cGUiOiAiRklVIgoJCX0sCgkJIkRhdGFQcm92aWRlciI6IHsKCQkJImlkIjogIkpJT1NBQVZOIiwKCQkJInR5cGUiOiAiRklQIgoJCX0sCgkJIkN1c3RvbWVyIjogewoJCQkiaWQiOiAiM2U0OTg4NmY3OTQxMzE5YmQ5YWQwNTAyY2U1MzI2MDc0OGNjZDY3NmEyNWQ2MDRiOGRjOWUzYTI0NjlhZGE0YyIKCQl9LAoJCSJQdXJwb3NlIjogewoJCQkiY29kZSI6ICIxMDEiLAoJCQkicmVmVXJpIjogImh0dHBzOi8vYXBpLnJlYml0Lm9yZy5pbi9hYS9wdXJwb3NlLzEwMS54bWwiLAoJCQkidGV4dCI6ICJDdXN0b21pc2VkIHNlcnZpY2VzIiwKCQkJIkNhdGVnb3J5IjogewoJCQkJInR5cGUiOiAic3RyaW5nIgoJCQl9CgkJfSwKCQkiRklEYXRhUmFuZ2UiOiB7CgkJCSJmcm9tIjogIjIwMjEtMDMtMTFUMTI6MDM6MjIuMDg3WiIsCgkJCSJ0byI6ICIyMDIyLTAzLTEwVDEyOjAzOjIyLjA4N1oiCgkJfSwKCQkiRGF0YUxpZmUiOiB7CgkJCSJ1bml0IjogIllFQVIiLAoJCQkidmFsdWUiOiAxCgkJfSwKCQkiRnJlcXVlbmN5IjogewoJCQkidW5pdCI6ICJNT05USCIsCgkJCSJ2YWx1ZSI6IDEKCQl9Cgl9Cn0='

    function openInNewTab() {
        navigate('/onb3')
       }

return(
    <div >
    
    <img src="/img7.PNG" onClick={openInNewTab} style={{width: '100%',height: '100%'}} />
    </div>
    
);
    
}

export default Onboard7;