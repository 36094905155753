import React,{useState,useEffect} from 'react'
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { makeAPICall } from '../../utils/ApiCall';
import base64 from 'base-64';
import { v4 as uuidv4 } from 'uuid';
import Main from '../consentflow/main';
import qs from 'query-string';


import { useNavigate,useParams, useLocation } from "react-router-dom";

const Onboard2 = (props) => {

    const navigate = useNavigate();
    const[showModal,setShowModal] = useState(false);
    const[success,setSuccess] = useState(false);

    const mobNumber = useSelector(state => state.common.mobNumber);
    const templateId = useSelector(state => state.common.tempId)
  
    // let tempId = 'ddf6d7c9-d7d2-499f-b5d1-078e0d84c71a'
    // let customerId = '3e49886f7941319bd9ad0502ce53260748ccd676a25d604b8dc9e3a2469ada4c'
    // let clientSecret = '25519c67-ccc1-4e0a-982f-ba7295ff994f'
    // let clientId = '3M2BYLLTC9'
    // let callbackUrl = 'http://localhost:4200'
    let tempId = templateId;
    let customerId = mobNumber;
    let clientSecret = 'ff8d0e98-2175-4f53-8152-dfc9d8373dd1'
    let clientId = 'GI8Z1T3M44'
    let callbackUrl = 'http://localhost:4200'

    const newQueryParam = {
        // tempId: tempId,
        // clientId: clientId,
        clientSecret: clientSecret,
        callbackUrl: callbackUrl,
        customerId: customerId,
        }
    
    // console.log(" param: " + JSON.stringify(newQueryParam))

    var encodedParam = base64.encode(JSON.stringify(newQueryParam));

    // console.log("encoded param: " + JSON.stringify(encodedParam))

    
    function openInNewTab() {
        // navigate({
            // pathname: `/${encodedParam}`,
            // search: qs.stringify(encodedParam)
        //   })
        navigate('/prereq')
       }
   

    const onAgree = () => {
        setSuccess(!success)
    }


    useEffect(() => {
        // console.log(mobNumber);
        // console.log(templateId);
     }, [mobNumber,templateId]);

return(
    <div >
    
    <img  src="/img2.PNG" style={{width: '100%',height: '100%'}} 
     onClick={openInNewTab}
      />

        {/* <Modal show={showModal} onHide={()=>setShowModal(!showModal)}
                centered
                class="close" data-dismiss="modal"
                >
                <Modal.Header >
                    <img  src="/jioLogo.PNG" className="img-responsive p-2" style={{width: '40px',height: '40px'}} />
                    <Modal.Title  >Consent Manager</Modal.Title>
                </Modal.Header>

            { !success ?
                <Modal.Body>
                    <Main agree={onAgree} close = {()=> setShowModal(!showModal)}/>     
                </Modal.Body>
                :
                
                <Modal.Body>
                    <Consent show={success} close = {()=> setShowModal(!showModal)} />     
                </Modal.Body>
                }

        </Modal> */}
        
    
        
    </div>
    
);
    
}

export default Onboard2;