import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import { BallTriangle } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style.css/custom.css';
import { CreateConsent, getProfileData, getPreferenceCenterThemeByGroupId, getTemplateDetailByConsentGroupId, getTemplatesByConsentGroupId, generateToken, saveParamsToState } from '../../store/actions/CommonAction'



import queryString from 'query-string';
import base64 from 'base-64'

const Main = (props) => {

  const tempDetails = useSelector(state => state.common.tempDetails)
  const preferenceCenterTheme = useSelector(state => state.common.theme)
  // const tempId = useSelector(state => state.common.tempId)
  // const customerId = useSelector(state => state.common.mobNumber)


  //const templateId = useSelector(state => state.common.templateId)
  //const consentGroupId = useSelector(state => state.common.consentGroupId)
  //const clientId = useSelector(state => state.common.clientId)
  //const customerId = useSelector(state => state.common.mobNumber)

  const profileData = useSelector(state => state.common.profileData)
  const appName = useSelector(state => state.common.App_Name)
  const Logo = useSelector(state => state.common.App_Logo)
  const getConsentGroupDetailsByGroupId = useSelector(state => state.common.getConsentGroupDetailsByGroupId);

  // Initialize an array of toggle states with false values
  const [toggleStates, setToggleStates] = useState(
    new Array(getConsentGroupDetailsByGroupId.length).fill(false));

  const avatarImage = (Logo == undefined || Logo == null) ?
    accountcircle : Logo;

  let consentGroupId = ''
  let customerId = ''
  let clientId = ''
  let clientSecret = ''

  // NON PROD
  // let clientSecret = 'ff8d0e98-2175-4f53-8152-dfc9d8373dd1'
  // let clientId = 'GI8Z1T3M44'

  // PROD
  // let clientSecret = 'df1b84da-f174-4428-99a6-506175e25cc7'
  // let clientId = 'FWURNARNHF'

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [showToggleShare, setShowToggleShare] = useState(false);
  const getTempDetails = useSelector(state => state.common.tempDetails);
  const [consentCheck, setConsentCheck] = useState(true);

  const [templateIdArray, setTemplateIdArray] = useState([]);


  let val = useParams();
  // console.log("path vals  " + JSON.stringify(val.data));
  let decodedString = base64.decode(val.data);
  let params = JSON.parse(decodedString)
  console.log("params vals MAIN PAGE " + JSON.stringify(params));

  // if(params){
  //   templateId = params.templateId
  //   customerId = params.customerId
  //   clientId = params.clientId
  //   clientSecret = params.clientSecret
  // }

  const toggleLoader = () => {
    setTimeout(() => setLoader(!loader), 3000);
  }

  const checkOtherEntitiesCheck = (templateId) => {

    if (templateIdArray.includes(templateId)) {
      // If the template ID is in the array, remove it
      const updatedTemplateIdArray = templateIdArray.filter(v => v !== templateId);
      setTemplateIdArray(updatedTemplateIdArray);
    } else {
      // If the template ID is not in the array, add it
      setTemplateIdArray([...templateIdArray, templateId]);
    }

    // if (!templateIdArray.includes(templateId)) {
    //   templateIdArray.push(templateId);
    //   setTemplateIdArray([...templateIdArray]);
    // } else {
    //   // If the templateId is already in the array, you can remove it.
    //   const updatedTemplateIdArray = templateIdArray.filter(v => v !== templateId);
    //   setTemplateIdArray(updatedTemplateIdArray);
    // }


    // if (!templateIdArray.includes(templateId)) {
    //   templateIdArray.push(templateId);
    // } else {
    //   templateIdArray = templateIdArray.filter(v => v !== templateId);
    // }

  }

  const accept = async () => {
    try {
      // alert("consentCheck : "+!consentCheck);
      document.getElementById("btnfont").style.display = "none";
      document.getElementById("consentLoader").style.display = "block";
      await dispatch(generateToken());
      await dispatch(CreateConsent(templateIdArray))
      await navigate(`/consent`)
    } catch (err) {
      console.log('error in screen ' + error + JSON.stringify(error))
    }
  }

  // const reject = async () => {
  //   try {
  //     // alert("consentCheck : "+!consentCheck);
  //     document.getElementById("btnfont").style.display = "none";
  //     document.getElementById("consentLoader").style.display = "block";
  //     await dispatch(generateToken());
  //     await dispatch(CreateConsent(templateIdArray))
  //     await navigate(`/consent`)
  //   } catch (err) {
  //     console.log('error in screen ' + error + JSON.stringify(error))
  //   }
  // }

  const toggleMenu = () => {
    setShowToggle(!showToggle);
  }
  // const toggleMenuShare = () => {
  //   setShowToggleShare(!showToggleShare);
  // }

  // Use useCallback to memoize the toggleMenuShare function
  const toggleMenuShare = useCallback(
    (index) => {
      const updatedToggleStates = [...toggleStates];
      updatedToggleStates[index] = !updatedToggleStates[index];
      setToggleStates(updatedToggleStates);
    },
    [toggleStates] // Make sure to include toggleStates in the dependency array
  );



  // const getDetail = async () => {
  //   try {
  //     if (templateId && customerId && clientId) {
  //       await dispatch(GetTempDetail(templateId, customerId, clientId))
  //       //alert("getTempDetails : "+JSON.stringify(getTempDetails))
  //       // if(getTempDetails != null){
  //       //   themeChanger('red3','#a80000')
  //       // }
  //     }
  //   }
  //   catch (error) {
  //     console.log('error in init screen ' + error + JSON.stringify(error))
  //     await setError(true)
  //     // await toast.error("Something went wrong please try again later !");
  //   }
  // }


  const getDetailByConsentGroupId = async () => {


    try {
      if (consentGroupId) {
        await dispatch(getTemplateDetailByConsentGroupId(consentGroupId));

        if (getConsentGroupDetailsByGroupId) {
          const mandatoryTemplateIds = getConsentGroupDetailsByGroupId
            .filter(singleTemplate => singleTemplate.templateDetails.mandatory === true)
            .map(singleTemplate => singleTemplate.templateId);

          // Initially, include the mandatory template IDs in templateIdArray
          setTemplateIdArray(mandatoryTemplateIds);
        }
      }
    } catch (error) {
      console.log('error in init screen ' + error + JSON.stringify(error));
      await setError(true);
      // Handle the error, e.g., display an error message
    }


    // //alert('inside getDetailByConsentGroupId')
    // try {
    //   if (consentGroupId) {
    //     ///alert('test' + consentGroupId)
    //     await dispatch(getTemplateDetailByConsentGroupId(consentGroupId));

    //     if (getConsentGroupDetailsByGroupId) {



    //       const newTemplateIdArray = [
    //         ...templateIdArray,
    //         ...getConsentGroupDetailsByGroupId
    //           .filter(singleTemplate => singleTemplate.templateDetails.mandatory === true)
    //           .map(singleTemplate => singleTemplate.templateId)
    //       ];

    //       // Update the state with the new array
    //       setTemplateIdArray(newTemplateIdArray);



    //       // for (var singleTemplate of getConsentGroupDetailsByGroupId) {

    //       //   if (singleTemplate.templateDetails.mandatory === true) {
    //       //     templateIdArray.push(singleTemplate.templateId);
    //       //     setTemplateIdArray()
    //       //   }

    //       // }

    //     }

    //   }
    // }
    // catch (error) {
    //   console.log('error in init screen ' + error + JSON.stringify(error))
    //   await setError(true)
    //   // await toast.error("Something went wrong please try again later !");
    // }
  }



  const themeChanger = (theme, color) => {
    // alert("loader : "+loader);

    // alert("theme is :"+theme);

    const app = document.getElementById("appColor").style;
    //alert("1");
    const panel = document.getElementById("panel").style;
    //alert("2");
    const panelHead1 = document.getElementById("panel-head1").style;
    //alert("3");
    const panelHead2 = document.getElementById("panel-head2").style;
    // alert("4");
    const activeLabel = document.getElementById("activeLabel").style;
    //alert("5");
    const switchTab = document.getElementById("switch").style;
    //alert("6");
    const inputCheck = document.getElementById("inputCheck").style;
    //alert("7");
    const slider = document.getElementById("slider").style;
    //alert("8");
    const button = document.getElementById("button").style;
    // alert("9");
    const logo = document.getElementById("brandLogo");
    // alert("10");
    const jioLogo = document.getElementById("jioLogo");
    // alert("11");




    if (theme == "red1") {

      app.backgroundColor = color;
      app.color = "#fff";
      panelHead1.backgroundColor = "#17a2b8";
      panelHead2.backgroundColor = "#17a2b8";
      panelHead1.color = "#fff";
      panelHead2.color = "#fff";
      button.backgroundColor = "#17a2b8";
      activeLabel.color = color;
      button.color = "#fff";
      slider.setProperty('--checked-bgdrop-color', '#ccc');
      slider.setProperty('--checked-bg-color', '#17a2b8');
      logo.src = "/JioSaavnLogoWhite.png";


    } else if (theme == "red2") {
      app.backgroundColor = "#fff";
      app.color = "#000";
      panelHead1.backgroundColor = color;
      panelHead2.backgroundColor = color;
      panelHead1.color = "#fff";
      panelHead2.color = "#fff";
      button.backgroundColor = color;
      activeLabel.color = "#fff";
      button.color = "#fff";
      // slider.setProperty('--before-bg-color', '#ccc');
      slider.setProperty('--checked-bgdrop-color', '#ffedb4');
      slider.setProperty('--checked-bg-color', color);
      logo.src = "/JioSaavnLogo.png";

    } else if (theme == "red3") {
      app.backgroundColor = color;
      app.color = "#fff";
      panelHead1.backgroundColor = "#f7ab20";
      panelHead2.backgroundColor = "#f7ab20";
      button.backgroundColor = "#f7ab20";
      panelHead1.color = "#fff";
      panelHead2.color = "#fff";
      activeLabel.color = color;
      button.border = "none";
      button.color = "#fff";
      slider.setProperty('--checked-bgdrop-color', '#fff5d6');
      slider.setProperty('--checked-bg-color', '#f7ab20 !important');
      logo.src = "/JioSaavnLogoWhite.png";

    } else if (theme == "blue1") {
      app.backgroundColor = color;
      app.color = "#fff";
      panelHead1.backgroundColor = "#9fe2ff";
      panelHead2.backgroundColor = "#9fe2ff";
      panelHead1.color = color;
      panelHead2.color = color;
      button.backgroundColor = "#9fe2ff";
      activeLabel.color = color;
      button.border = "none";
      button.color = color;
      slider.setProperty('--checked-bgdrop-color', '#30c0ff');
      slider.setProperty('--checked-bg-color', color);
      logo.src = "/JioSaavnLogoWhite.png";

    } else if (theme == "blue2") {
      app.backgroundColor = "#fff";
      app.color = "#000";
      panelHead1.backgroundColor = color;
      panelHead2.backgroundColor = color;
      panelHead1.color = "#fff";;
      panelHead2.color = "#fff";;
      button.backgroundColor = color;
      button.color = "#fff";
      activeLabel.color = "#fff";
      button.border = "none";
      slider.setProperty('--checked-bgdrop-color', '#d6d6ff');
      slider.setProperty('--checked-bg-color', color);
      logo.src = "/JioSaavnLogo.png";

    }



  }


  // const getPreferenceCenterTheme = async () => {

  //   try {
  //     if (clientId) {
  //       await dispatch(getPreferenceCenterThemeByClient(clientId))

  //       // alert(" we have the preferenceCenterTheme : "+preferenceCenterTheme.theme);


  //       if (preferenceCenterTheme.theme != undefined) {
  //         //alert("hee");
  //         const themeObj = JSON.parse(preferenceCenterTheme.theme);
  //         document.getElementById("descPara").innerHTML = preferenceCenterTheme.desc;
  //       } else {
  //         // alert("in here");
  //         document.getElementById("descPara").innerHTML = "While using " + appName + ", your activities create data that will be used by " + appName + " to offer you better and more customized services. " + appName + " uses this data in accordance with your permission (consent), details of which are clearly mentioned below. We do this, so that YOU are always aware of how we use your data, and YOU have total control on managing the usage of your data";
  //       }


  //       //alert("in object form : "+themeObj);
  //       console.log("in data form : " + JSON.stringify(themeObj));

  //       applytheme(themeObj);


  //     }
  //   }
  //   catch (error) {
  //     console.log('error in init screen ' + error + JSON.stringify(error))
  //     await setError(true)
  //     // await toast.error("Something went wrong please try again later !");
  //   }

  // }



  const getPreferenceCenterThemeDetailsByGroupId = async () => {
    //alert('getPreferenceCenterThemeDetailsByGroupId')
    try {
      if (consentGroupId) {
        await dispatch(getPreferenceCenterThemeByGroupId(consentGroupId))

        // alert(" we have the preferenceCenterTheme : "+preferenceCenterTheme.theme);


        if (preferenceCenterTheme.theme != undefined) {
          //alert("hee");
          const themeObj = JSON.parse(preferenceCenterTheme.theme);
          document.getElementById("descPara").innerHTML = preferenceCenterTheme.topDescription;
        } else {
          // alert("in here");
          document.getElementById("descPara").innerHTML = "While using " + appName + ", your activities create data that will be used by " + appName + " to offer you better and more customized services. " + appName + " uses this data in accordance with your permission (consent), details of which are clearly mentioned below. We do this, so that YOU are always aware of how we use your data, and YOU have total control on managing the usage of your data";
        }

        if (preferenceCenterTheme.theme != undefined) {
          const themeObj = JSON.parse(preferenceCenterTheme.theme);
          document.getElementById("descParaBottom").innerHTML = preferenceCenterTheme.bottomDescription;
        } else {
          document.getElementById("descPara").innerHTML = "By tapping/clicking the ‘I Agree’ button below, you are giving your permission (consent) to " + appName + " to use your data in accordance with the parameters mentioned above.";
        }


        //alert("in object form : "+themeObj);
        console.log("in data form : " + JSON.stringify(themeObj));

        applytheme(themeObj);


      }
    }
    catch (error) {
      console.log('error in init screen ' + error + JSON.stringify(error))
      await setError(true)
      // await toast.error("Something went wrong please try again later !");
    }

  }



  const callAPI = async () => {
    try {
      await dispatch(getProfileData(clientId))

    } catch (error) {
      console.error(error)
    }
  }

  const applytheme = async (theme) => {

    // alert("in apply theme")


    // const panel = document.getElementById("panel").style;
    // const panelHead1 = document.getElementById("panel-head1").style;
    // const panelHead2 = document.getElementById("panel-head2").style;
    // const activeLabel = document.getElementById("activeLabel").style;
    // const switchTab = document.getElementById("switch").style;
    // const inputCheck = document.getElementById("inputCheck").style;
    // const slider = document.getElementById("slider").style;
    // const button = document.getElementById("button").style;



    //applying theme for card
    const app = document.getElementById("appColor").style;
    app.backgroundColor = theme.card.backgroundColor;
    // app.padding = theme.card.padding;
    app.width = theme.card.width;

    //applying theme for panel
    const panelHead1 = document.getElementById("panel-head1").style;
    const panelHead2 = document.getElementById("panel-head2").style;

    panelHead1.backgroundColor = theme.panelHead1.backgroundColor;
    panelHead1.padding = theme.panelHead1.padding;
    panelHead1.color = theme.panelHead1.color;
    panelHead1.fontSize = theme.panelHead1.fontSize;

    panelHead2.backgroundColor = theme.panelHead2.backgroundColor;
    panelHead2.padding = theme.panelHead2.padding;
    panelHead2.color = theme.panelHead2.color;
    panelHead2.fontSize = theme.panelHead2.fontSize;

    //applying theme for button

    const button = document.getElementById("button").style;

    button.backgroundColor = theme.button.backgroundColor;
    button.padding = theme.button.padding;
    button.color = theme.button.color;
    button.fontSize = theme.button.fontSize;


  }

  const [loadingMain, setLoadingMain] = useState(true);

  useEffect(() => {
    dispatch(generateToken())
    //parametere read frorm URL
    let encodedParam = window.location.pathname;
    var encData = encodedParam.split("/");
    //alert('URL Caputured-->'+ encData[1]);
    let decodedParam = base64.decode(encData[1]);
    //alert('Decoded Capt-->' +decodedParam);
    let paramObject = JSON.parse(decodedParam);

    clientId = paramObject.clientId;
    clientSecret = paramObject.clientSecret;
    consentGroupId = paramObject.consentGroupId;
    customerId = paramObject.customerId;
    dispatch(saveParamsToState(customerId, clientId, clientSecret, consentGroupId));

    //alert("clientId"+paramObject.clientId);

    toggleLoader()
    callAPI()

    getProfileData(clientId);
    //getPreferenceCenterTheme();
    getDetailByConsentGroupId();
    getPreferenceCenterThemeDetailsByGroupId();

    setLoadingMain(false);
    // console.log('val is ,',JSON.stringify(tempDetails))
  }, []);

  return (
    <>

      {loader &&
        <div className="App-header">
          <BallTriangle color="#0000CC" height={80} width={80} />
          <p className="p-2" style={{ color: '#0000CC' }} >Taking you to Jio consent manager...</p>
        </div>}

      {/* !loader && !error &&  */}
      <div className="App-color col-sm-4 main-container" id="appColor" style={{ left: '30%', marginTop: '2%', height: '550px' }}>

        <Modal.Header style={{ padding: '9px 15px', height: '51px', border: 'none', justifyContent: "left", marginBottom: "2%" }}>
          <div className="d-flex align-items-center">
            <img
              src={avatarImage}
              style={{ height: '50px', maxWidth: '50px', padding: '0px', marginRight: '10px', objectFit: 'contain' }}
              id="Logo"
              className="img-thumbnail preferenceCenter-image"
            />
            <div>
              <p style={{ marginTop: '0', marginBottom: '0', fontSize: '18px' }} id='appname'>{appName}</p>
            </div>

          </div>
        </Modal.Header>


        <Modal.Body style={{ overflow: 'auto', height: '87%' }}>

          <div>
            <p id="descPara" style={{ fontSize: 12 }}>

            </p>

            <p style={{ fontSize: 12 }}>Privacy policy and terms and Conditions </p>
            <h3 style={{ fontSize: 13, fontWeight: 'bold' }}>Manage Consent Preferences: </h3>

            <div class="panel panel-default" id="panel" style={{ borderRadius: '4px' }}>
              <div class="panel-heading" id="panel-head1" onClick={toggleMenu}>
                <img src="/plus.png" className="img-responsive p-0" style={{ height: '14px', marginRight: '3px', marginBottom: '2px', }} />
                Data Used By {appName}
                {/* <label class="switch">
                      <input type="checkbox" checked disabled></input>
                      <span class="slider round"></span>
                    </label> */}
                <label class="activeLabels" id="activeLabel">Always Active</label>
              </div>
              <div class="panel-body" style={showToggle ? { display: "block" } : { display: 'none' }} >
                {
                  getConsentGroupDetailsByGroupId &&
                  getConsentGroupDetailsByGroupId.map((item, key) => {
                    return item.templateDetails.mandatory == true ? (
                      <table className="table table-border less table-sm table-striped">
                        <tbody>
                          <tr>
                            <td>Data Used By</td>
                            <td>:</td>
                            {/* <td >{appName}</td> */}
                            <td >
                              {
                                item.templateDetails.dataConsumer.map((ele, key) => (
                                  <React.Fragment key={key}>{ele}{key === item.templateDetails.dataConsumer.length - 1 ? '' : ', '}</React.Fragment>
                                ))}
                            </td>
                          </tr>
                          <tr>
                            <td>Data Used</td>
                            <td>:</td>
                            <td >
                              {
                                item.templateDetails.consentTypes.map((ele, key) => (
                                  <React.Fragment key={key}>{ele}{key === item.templateDetails.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                                ))}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '33%' }}>Consent Duration</td>
                            <td>:</td>
                            <td >{item.templateDetails.consentValidity.value} <span style={{ textTransform: 'lowercase' }}>{item.templateDetails.consentValidity.unit}</span></td>
                          </tr>
                          <tr>
                            <td>Purpose</td>
                            <td>:</td>
                            <td>Providing customized services and functional/performance enhancement</td>
                          </tr>
                        </tbody>
                      </table>
                    ) : null;
                  }
                  )
                }


              </div>
            </div>

            {
              getConsentGroupDetailsByGroupId &&
              getConsentGroupDetailsByGroupId.map((item, index) => {
                return item.templateDetails.mandatory == false ? (


                  <div class="panel panel-default" id="panel" key={index}>
                    <div class="panel-heading" id="panel-head2" onClick={() => toggleMenuShare(index)}>
                      <img src="/plus.png" className="img-responsive p-0" style={{ height: '14px', marginRight: '3px', marginBottom: '2px' }} /> Data Shared with Other Entities
                      {/* <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" />
                  </div>  */}

                      <label class="switch" id="switch">
                        <input type="checkbox" id="inputCheck" onClick={() => checkOtherEntitiesCheck(item.templateId)}></input>
                        <span class="slider round" id="slider"></span>
                      </label>

                    </div>
                    <div class="panel-body" style={toggleStates[index] ? { display: "block" } : { display: 'none' }} >

                      <table className="table table-border less table-sm table-striped" style={{ 'marginTop': '5%' }}>
                        <tbody>
                          <tr>
                            <td>Data Shared With</td>
                            <td>:</td>
                            {/* <td >{item.templateDetails.dataConsumer}</td> */}
                            <td >
                              {
                                item.templateDetails.dataConsumer.map((ele, key) => (
                                  <React.Fragment key={key}>{ele}{key === item.templateDetails.dataConsumer.length - 1 ? '' : ', '}</React.Fragment>
                                ))}
                            </td>
                          </tr>
                          <tr>
                            <td>Data Shared</td>
                            <td>:</td>
                            <td >
                              {
                                item.templateDetails.consentTypes.map((ele, key) => (
                                  <React.Fragment key={key}>{ele}{key === item.templateDetails.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                                ))}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '33%' }}>Consent Duration</td>
                            <td>:</td>
                            <td >{item.templateDetails.consentValidity.value} <span style={{ textTransform: 'lowercase' }}>{item.templateDetails.consentValidity.unit}</span></td>
                          </tr>
                          <tr>
                            <td>Purpose</td>
                            <td>:</td>
                            <td>Perform analytics and present targeted advertisements</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                ) : null;
              }
              )
            }

            <p id="descParaBottom" style={{ fontSize: 13, paddingTop: '15px' }}>

            </p>

            <div style={{ marginLeft: '28%', marginTop: '7%' }}>
              <button onClick={accept} type="button" id="button" className="btn btn-info m-2 " style={{ marginLeft: 11, backgroundColor: '#f7ab20', border: 'none', minWidth: '57%', textAlign: '-webkit-center' }}><font id="btnfont">Confirm Selection</font><img src="/consentLoader.gif" id="consentLoader" className="img-responsive p-0" style={{ height: '35px', display: 'none' }} /></button>
              <br></br>
              <small style={{ fontSize: 11 }}>Powered by</small> &nbsp;
              <img src="/JioLogoMarigold.png" id="jioLogo" className="img-responsive p-0" style={{ height: '20px' }} />&nbsp;
              <small style={{ fontSize: 11 }}>Consent Manager</small>
            </div>

            {/* <p style={{fontSize:10,paddingTop:'15px'}}>We maintain full transparency into how and for what purpose your data is used. A complete overview of your data’s usage can be accessed from Jio Consent Manager app or by visiting consent.jio.com</p> */}
          </div>

        </Modal.Body >



      </div>



      {/* <div class="themeSelector">
              <small>Theme Selector (<b>to be removed</b>)</small>
              <br></br><br></br>
              <button class="btn btn-danger" style={{backgroundColor:"#e30513"}} onClick={()=>{ themeChanger('red2','#e30513')}}>Red theme 2</button><br></br><br></br>
              <button class="btn btn-danger" style={{backgroundColor:"#e30513"}} onClick={()=>{ themeChanger('red3','#a80000')}}>Red theme 3</button><br></br><br></br>
          </div> */}
      {/* {error &&
        <div className="App-header">
          <p className="p-2" style={{ color: 'red' }} >Something went wrong please try again later...</p>
        </div>
      } */}


      {/* <ToastContainer 
            draggable
            theme='colored' 
        /> */}


    </>
  );
}

export default Main;



// const notify = () => toast.error("Something went wrong please try again later !", {
//   position: toast.POSITION.BOTTOM_RIGHT,
// });



// const val  = useParams();
// console.log("path vals  " + JSON.stringify(val));
// var decodedString = base64.decode(val.data);
// let body = JSON.parse(decodedString)
// console.log("path vals  " + JSON.stringify(body));

// let body = val.data
//     // console.log("path vals  " + JSON.stringify(body));
//     body = body.split('&&')
//     let temp = body[0]
//     let customerId = body[1]
//     let clientSecret = body[2]
//     let clientId = body[3]
// console.log("path id  " + JSON.stringify(customerId));
// console.log("path temp  " + JSON.stringify(temp));
