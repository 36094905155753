import React, {  useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import sha256 from 'sha256';
import { saveTempId,generateToken } from '../../store/actions/CommonAction';
import base64 from 'base-64';
import qs from 'query-string';


const Preonboarding = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clientId, setClientId] = useState('');
    const [clientSecret, setclientSecret] = useState('');

    //const [templateId, setTempId] = useState('');
    const [consentGroupId, setconsentGroupId] = useState('');
    const [mob, setMob] = useState('');

    // const mobNumber = useSelector(state => state.common.mobNumber);
    // const templateId = useSelector(state => state.common.tempId)


    const handleSubmit = (e) => {
        e.preventDefault();
        savedata()
    }

    const savedata = async() => {
        let mobilehash=sha256(mob);
        console.log(mobilehash);
        await dispatch(saveTempId(consentGroupId,mobilehash,clientId,clientSecret))
        // await navigate("/main");
        navigate({
            pathname: `/${encodedParam}`,
            search: qs.stringify(encodedParam)
          })
    
      }

      if(clientId  && consentGroupId && mob){
        const newQueryParam = {
            clientId: clientId,
            clientSecret: clientSecret,
            consentGroupId: consentGroupId,
            customerId: sha256(mob)
            }
        // console.log(" param: " + JSON.stringify(newQueryParam))
        var encodedParam = base64.encode(JSON.stringify(newQueryParam));
        // console.log("encoded param: " + JSON.stringify(encodedParam))
      }
     

    const  openInNewTab =()=> {
        // navigate({
        //     pathname: `/${encodedParam}`,
        //     search: qs.stringify(encodedParam)
        //   })
        // navigate('/prereq')
       }

       useEffect(() => {
        dispatch(generateToken())
        // generateToken()
    },[])
   
    
    return (
        <div>
            <div  className="d-flex justify-content-center">

                <div style={{width:'400px'}}>

                    <Modal.Header >
                        <div className="d-flex align-items-center justify-content-center">
                            {/* <img  src={loginImg} alt="login" className="img-responsive p-1" style={{width: '70px',height: '70px'}} /> */}
                            <div className="row text-center ">
                                <Modal.Title   >Prerequisite</Modal.Title>
                            </div>
                        </div>
                    </Modal.Header>

                    <p style={{fontSize: '16px',textAlign:'center',color:'blue'}} className="mt-3">Kindly enter below details to proceed further.</p>
                    <p style={{fontSize: '16px',textAlign:'center',color:'red'}} >Note: This page only used for demonstration purpose.</p>


                    <form onSubmit={handleSubmit} className=" p-3 needs-validation was-validated align-self-center">

                                <div >
                                    <label className="form-label">Consent GroupId</label>
                                    <input 
                                        className="form-control" 
                                        placeholder="Enter Consent GroupId" 
                                        required autoFocus 
                                        onChange={(e)=>{ setconsentGroupId(e.target.value)}}
                                        // onKeyPress={(event) => {
                                        //     if (!/[0-9a-z-]/.test(event.key)) {
                                        //         event.preventDefault();
                                        //     }
                                        // }}
                                            />
                                </div>

                                <div className='mt-2'>
                                    <label className="form-label">Client Id</label>
                                    <input 
                                        className="form-control " 
                                        placeholder="Enter ClientId" 
                                        required autoFocus
                                        maxLength="10"
                                        minLength="10"
                                        // style={{textTransform:'uppercase'}} 
                                        onChange={(e)=>{ setClientId(e.target.value)}}
                                        onKeyPress={(event) => {
                                            if (!/[0-9A-Za-z]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                            />
                                </div>
                                <div className='mt-2'>
                                    <label className="form-label">Client Secret</label>
                                    <input 
                                        className="form-control " 
                                        placeholder="Enter client secret" 
                                        required autoFocus
                                        onChange={(e)=>{ setclientSecret(e.target.value)}}
                                        onKeyPress={(event) => {
                                            if (!/[0-9A-Za-z ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                            />
                                </div>

                                <div className='mt-2'>
  <label className="form-label">Mobile number</label>
  <input 
    className="form-control w-100" 
    placeholder="Enter mobile number" 
    required 
    maxLength={10} minLength={10}
    value={mob} // Assuming `mob` is the state holding the mobile number
    onChange={(e) => setMob(e.target.value)}
    onKeyPress={(event) => {
      // Allow only numeric characters (0-9)
      if (!/^[0-9]+$/.test(event.key)) {
        event.preventDefault();
      }
    }}
    onPaste={(event) => {
      // Prevent pasting non-numeric content
      const pastedText = event.clipboardData.getData('text');
      if (!/^[0-9]+$/.test(pastedText)) {
        event.preventDefault();
      }
    }}
  />
</div>



                                <div className="d-flex justify-content-center">
                                    <button  className="btn btn-outline-primary btn-sm mt-3 mb-3 d-flex align-self-center" 
                                        disabled={!(mob && consentGroupId && clientId)}
                                        //onClick={savedata}
                                         >PROCEED</button>
                                </div>

                    </form>

                </div>

            </div>

            <ToastContainer 
            // type={toastType}
            // theme={'colored'}
            />
        </div>
    );
}

export default Preonboarding