import {
    INIT_CALL, CONSENT_DATA, CONSENT_AUTHORIZED, CONSENT_HISTORY, SAVE_TEMPID,
    SAVE_AUTH_TOKEN,
    GET_TEMP_DETAILS,
    GET_PREFERENCE_CENTER_THEME, PROFILE_DATA, APP_LOGO, APP_NAME, GET_CONSENTGROUPDATABY_GROUPID, paramsFromState,paramsFromStateHistory
} from "../actions/CommonAction";

const initialState = {
    consentHandle: '',
    consentData: '',
    ActiveList: '',
    RevokeList: '',
    AllConsent: '',
    profileData: '',
    tempDetails: '',
    clientId: '',
    clientSecret: '',
    consentGroupId: '',
    customerId: '',
    tempId: '',
    mobNumber: '',
    access_token: '',
    theme: '',
    App_Logo: "",
    App_Name: "",
    getConsentGroupDetailsByGroupId: '',

}



const CommonReducer = (state = initialState, actions) => {

    switch (actions.type) {
        case SAVE_AUTH_TOKEN:
            return {
                ...state,
                access_token: actions.access_token
            }
        case INIT_CALL:
            return {
                ...state,
                consentHandle: actions.consentHandle,
            }

        case CONSENT_DATA:
            return {
                ...state,
                consentData: actions.data,
            }
        case CONSENT_DATA:
            return {
                ...state,
                consentData: actions.data,
            }
        case CONSENT_AUTHORIZED:
            return {
                ...state,
                consentHandle: '',
            }
        case CONSENT_HISTORY:
            return {
                ...state,
                ActiveList: actions.ActiveList,
                RevokeList: actions.RevokeList,
                AllConsent: actions.AllConsent
            }

        case GET_TEMP_DETAILS:
            return {
                ...state,
                tempDetails: actions.data,
                // clientId:actions.clientId,
                // clientSecret:actions.clientSecret,
                templateId: actions.tempId,
                customerId: actions.customerId
            }

        case GET_CONSENTGROUPDATABY_GROUPID:
            //console.log("Reducer------>"+JSON.stringify(actions.data));
            return {
                ...state,
                getConsentGroupDetailsByGroupId: actions.templates
            }

        case GET_PREFERENCE_CENTER_THEME:
            return {
                ...state,
                theme: actions.data
            }
        case PROFILE_DATA:
            return {
                ...state,
                access_token: actions.access_token,
                profileData: actions.profileData,
                logo: actions.logo
            }
        case APP_NAME:

            return {

                ...state,

                App_Name: actions.App_Name

            }

        case APP_LOGO:

            return {

                ...state,

                App_Logo: actions.App_Logo

            }

        case paramsFromState:

            return {
                ...state,
                consentGroupId: actions.consentGroupId,
                customerId: actions.customerId,
                clientId: actions.clientId,
                clientSecret: actions.clientSecret
            }

            case paramsFromStateHistory:

            return {
                ...state,
                customerId: actions.customerId,
                clientId: actions.clientId,
                clientSecret: actions.clientSecret
            }

        case SAVE_TEMPID:
            //console.log("val in reducer ", actions.templateId , actions.mob , actions.clientId, actions.clientSecret )
            return {
                ...state,
                consentGroupId: actions.consentGroupId,
                mobNumber: actions.mob,
                clientId: actions.clientId,
                clientSecret: actions.clientSecret
            }

        default: return state;
    }

}

export default CommonReducer;