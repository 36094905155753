import React,{useState} from 'react'
import { Modal } from "react-bootstrap";
import { makeAPICall } from '../../utils/ApiCall';
import config from '../../utils/config';
import { v4 as uuidv4 } from 'uuid';
//import '../../utils/cookieConsent'

import { useNavigate,useParams, useLocation } from "react-router-dom";

const Onboard = (props) => {

    const navigate = useNavigate();



return(
    <div >
    
    <img src="/img.PNG" style={{width: '100%',height: '100%'}} 
            onClick={()=>{navigate('/onb1')}} />
    
        
    </div>
    
);
    
}

export default Onboard;