import React,{useState} from 'react'
import { Modal } from "react-bootstrap";
import { makeAPICall } from '../../utils/ApiCall';
import config from '../../utils/config';
import { v4 as uuidv4 } from 'uuid';

import { useNavigate,useParams, useLocation } from "react-router-dom";

const Onboard5 = (props) => {

    const navigate = useNavigate();
    
    let cid = '3e49886f7941319bd9ad0502ce53260748ccd676a25d604b8dc9e3a2469ada4c'

    function openInNewTab() {
        // window.open(`http://localhost:3000/consenthistory/${cid}`, '_blank').focus();
        navigate(`/consenthistory/${cid}`)
        // navigate(`/consenthistory`)
       }

return(
    <div >
    
    <img src="/img5.PNG" onClick={openInNewTab} style={{width: '100%',height: '100%'}} />
    </div>
    
);
    
}

export default Onboard5;