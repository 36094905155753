import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import {
  Link
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleTwoTone } from '@ant-design/icons';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { Modal } from "react-bootstrap";
import moment from "moment";
import { updateStatus } from '../../store/actions/CommonAction'

const Tab2 = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState('');
  const [val, setVal] = useState('');


  const RevokeList = useSelector(state => state.common.RevokeList)
  console.log('revoke list-->' + JSON.stringify(RevokeList));

  // const click = () => {
  //   navigate("/onb5")
  // }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const viewDetail = (item) => {
    setItem(item)
    setShowModal(!showModal)
  }

  const revoke = async (id) => {
    // await dispatch(updateStatus(id))
    await setShowModal(!showModal)
  }

  const revokenotification = async () => {
    await toast.info("Revoke Feature Coming Soon..!");
  }

  // const call =()=>{
  //   fetch('https://api.github.com/users').then((response)=>response.json()).
  //   then(res=>{
  //     setVal(res)
  //     console.log("user resp",JSON.stringify(res))
  //   })
  // }

  // useEffect(() => {
  //  call()
  // }, []);

  return (
    <div className="App-bg" style={{ background: "#fff" }}>


      {/* { 
            RevokeList && RevokeList.length !== 0 ? 

            RevokeList.map((item, key) => (
                  <Card border="info" className="mb-2" key={key}>
                      <Card.Body key={key}>
                      <table className="table table-sm borderless tablepadding0" >
                          <tbody>
                            <tr>
                              <td>Data Used By</td>
                              <td >{item.dataConsumer}</td>
                            </tr>
                            <tr>
                              <td>Data Types Used</td>
                              <td >
                             
                                  {
                                    item.consentTypes.map((ele,key)=> (
                                    <>{ele}</> 
                                    ))}
                              </td>
                            </tr>
                            <tr>
                              <td>Purpose of Data Used</td>
                              <td >{item.purpose.text}</td>
                            </tr>
                            <tr>
                              <td>Consent Duration</td>
                              <td >{item.consentValidity.value} {item.consentValidity.unit}</td>
                            </tr>
                            <tr>
                              <td>Consent Manager</td>
                              <td >Jio Consent Manager</td>
                            </tr>
                          </tbody>
                        </table> 
                      </Card.Body>
                      <button  type="button" onClick={()=>viewDetail(item)} className="btn btn-info m-0">View Details</button>
                    </Card>
              ))
            :
             <Modal.Header className="App">
                    <Modal.Title style={{fontSize:14}} >No revoked consent</Modal.Title>
                </Modal.Header>
            } */}


      <div style={{ overflowX: "auto" }}>
        <table className="table table-sm borderless table-striped" style={{ minWidth: "600px" }}>
          {
            RevokeList && RevokeList.length !== 0 &&
            <thead className="text-left" style={{ fontSize: '14px', color: '#660209' }}>
              <tr>
                <th scope="col">Data Used By</th>
                <th scope="col">Data Types Used </th>
                <th scope="col">Purpose of Data Used</th>
                <th scope="col">Consent Duration</th>
                <th scope="col">Consent Creation Date</th>
                <th scope="col">Consent Manager</th>
              </tr>
            </thead>
          }
          <tbody>{
            RevokeList.map((item, key) => (
              //moment(item.createTimestamp).format("DD-MM-YYYY hh:mm:ss") >= moment(new Date(new Date().getTime() - (1000*60*60))).format("DD-MM-YYYY hh:mm:ss") &&
              <React.Fragment key={key}>

                <tr>
                  <td>{item.dataConsumer}</td>
                  <td>

                    {
                      item.consentTypes.map((ele, key) => (
                        <React.Fragment key={key}>{ele}{key === item.consentTypes.length - 1 ? '' : ', '}</React.Fragment>
                      ))}
                  </td>
                  <td >{item.purpose}</td>
                  <td >{item.consentValidity.value} {item.consentValidity.unit}</td>
                  <td >{moment(item.createTimestamp).format("DD-MM-YYYY hh:mm:ss")}</td>
                  <td >Jio Consent Manager</td>
                  {/* &nbsp;
                              {moment(item.createTimestamp).format("DD-MM-YYYY hh:mm:ss")} &nbsp;
                              {moment(new Date()).format("DD-MM-YYYY hh:mm:ss")} */}
                  <td><button type="button" onClick={() => viewDetail(item)} className="btn btn-info m-0" style={{ backgroundColor: '#660209' }}>View Details</button></td>

                </tr>
                {/* <tr>
                              <td>Data Types Used</td> 
                              <td >
                             
                                  {
                                    item.consentTypes.map((ele,key)=> (
                                    <React.Fragment key={key}>{ele}{key ===  item.consentTypes.length - 1? '' : ', '}</React.Fragment> 
                                    ))}
                              </td>
                            </tr>
                            <tr>
                              <td>Purpose of Data Used</td>
                              <td >{item.purpose.text}</td>
                            </tr>
                            <tr>
                              <td>Consent Duration</td>
                              <td >{item.consentValidity.value} {item.consentValidity.unit}</td>
                            </tr>
                            <tr>
                              <td>Consent Manager</td>
                              <td >Jio Consent Manager</td>
                            </tr> */}

              </React.Fragment>

            ))
          }
          </tbody>
          {/* :
             <Modal.Header className="App">
                    <Modal.Title style={{fontSize:14}} >No active consent</Modal.Title>
                </Modal.Header> */}

        </table>
      </div>
      {/* <button type="button" onClick={click} className="btn btn-info m-4" >Take me back to JioSaavn</button> */}

      {/* {val &&
                val.map((item)=>(
                  <Modal.Title style={{color:'black'}}  >{item.login}</Modal.Title>
                ))
            } */}

      {
        item &&
        <Modal show={showModal} onHide={() => setShowModal(!showModal)}
          centered class="close App" data-dismiss="modal"
        >
          <Modal.Header className="App" >
            <Modal.Title  >Consent Details</Modal.Title>
          </Modal.Header>

          <Modal.Body className="App" >
            <Card.Body >
              <div class="table-responsive-sm text-center">

                <table className="table-sm table-responsive d-flex justify-content-around">

                  <tbody className="p-0 m-0">
                    <tr>
                      <td>Consent ID</td>
                      <td>:</td>
                      <td>{item.consentId}</td>
                    </tr>
                    <tr>
                      <td>Data Consumer</td>
                      <td>:</td>
                      {/* <td>JPL</td> */}
                      {/* <td>{item.dataConsumer }</td> */}
                      <td>
                        {
                          item.dataConsumer.map((ele, key) => (
                            <React.Fragment key={key}>{ele}{key === item.dataConsumer.length - 1 ? '' : ', '}</React.Fragment>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Data Provider</td>
                      <td>:</td>
                      {/* <td>JIOSAAVN</td> */}
                      <td>{item.dataProvider}</td>
                    </tr>
                    <tr>
                      <td>Purpose</td>
                      <td>:</td>
                      {/* <td>{item.purpose}</td> */}
                      <td>
                        {
                          item.purpose.map((ele, key) => (
                            <React.Fragment key={key}>{ele}{key === item.purpose.length - 1 ? '' : ', '}</React.Fragment>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Data Fields</td>
                      <td>:</td>
                      <td>
                        {
                          item.consentTypes.map((ele, key) => (
                            <>{ele}{key == 2 ? '' : ', '}</>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>:</td>
                      <td>{item.status}</td>
                    </tr>
                    {/* <tr>
                              <td>From</td>
                              <td>:</td>
                              <td>{moment(item.fiDataRange.from).format("DD-MM-YYYY HH:mm")}</td>
                            </tr>
                            <tr>
                              <td>To</td>
                              <td>:</td>
                              <td>{moment(item.fiDataRange.to).format("DD-MM-YYYY HH:mm")}</td>
                            </tr> */}
                    <tr>
                      <td>Data Life</td>
                      <td>:</td>
                      <td>{item.dataLife.value} {item.dataLife.unit} </td>
                    </tr>
                    <tr>
                      <td>Frequency</td>
                      <td>:</td>
                      <td>{item.frequency.value} {item.frequency.unit}</td>
                    </tr>
                    <tr>
                      <td>Fetch type</td>
                      <td>:</td>
                      <td>{item.fetchType}</td>
                    </tr>
                    <tr>
                      <td>Start Date & Time</td>
                      <td>:</td>
                      <td>{moment(item.consentStart).format("DD-MM-YYYY HH:mm")}</td>
                    </tr>
                    <tr>
                      <td>End Date & Time</td>
                      <td>:</td>
                      <td>{moment(item.consentExpiry).format("DD-MM-YYYY HH:mm")}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </Card.Body>
          </Modal.Body>

          <div className="text-center App p-4">
            {/* <button type="button" onClick={()=>revoke(item.consentId)} className="btn btn-outline-danger m-2 p-2">Revoke</button> */}
            {/* <button type="button" onClick={revokenotification} className="btn btn-outline-danger m-2 p-2">Revoke</button> */}
            <button type="button" onClick={toggleModal} className="btn btn-outline-secondary m-2 p-2">Close</button>
          </div>

        </Modal>
      }

      <ToastContainer />

    </div>
  );
}

export default Tab2;
