import * as React from 'react';
import {useState } from 'react'

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import TabPanel from '@mui/lab/TabPanel';
import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';


import Tab1 from '../screen/Tabs/tab1';
import Tab2 from '../screen/Tabs/tab2';


export default function CenteredTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;
  
  //   return (
  //     <div className="App">
  //     <header className="App-header">

        
  //      <CheckCircleTwoTone className="m-2"  style={{fontSize:'50px',color: '#08c'}} />
  //      <h1  style={{fontSize:'20px',color:'black'}}>Tab 1 !!</h1>
  //     </header>
  //   </div>
  //   );
  // }

 

  return (
      <>
     <Box sx={{ background: '#fff', width: '100%' }} 
     className="App" 
     variant="fullWidth"
     >
      <TabContext value={value}>
        <Box centered>
        <Tabs value={value}
              variant="fullWidth" 
              indicatorColor="primary"
              textColor="inherit"
              onChange={handleChange} >
            <Tab  label="ACTIVE" value="1" />
            <Tab label="REVOKED" value="2" />
          </Tabs>
        </Box>
        <TabPanel value="1"><Tab1/></TabPanel>
        <TabPanel value="2"> <Tab2/> </TabPanel>
      </TabContext>
    </Box>
    </>
  );
}
