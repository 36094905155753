import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { initConsent, updateStatus, consentByCustomerId, generateToken, consentBytxnId, saveParamsToStateHistory } from '../../store/actions/CommonAction'
import base64 from 'base-64'
import Card from 'react-bootstrap/Card';

import { Modal } from "react-bootstrap";
import queryString from 'query-string';
import { BallTriangle } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tabs from '../../component/Tab';
import CenteredTabs from '../../component/Tab';

const ConsentHistory = (props) => {

  // const history = useHistory()
  // const location = useLocation();
  // let paramsVal = queryString.parse(location.search)
  // console.log("paramsVal  ");
  // console.log(paramsVal);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)

  const val = useParams();
  // console.log("cid vals  " + JSON.stringify(val.cid));

  //let consentGroupId = ''
  let customerId = ''
  let clientId = ''
  let clientSecret = ''


  const consentHistory = async () => {
    try {

      //parametere read frorm URL
      let encodedParam = window.location.pathname;
      var encData = encodedParam.split("/");
      //alert('URL Inside-->'+ encData[2]);
      let decodedParam = base64.decode(encData[2]);
      //alert('Decoded Captr-->' +decodedParam);
      let paramObject = JSON.parse(decodedParam);

      //alert("paramObject L "+JSON.stringify(paramObject))

    clientId = paramObject.clientId;
      //alert('clientId'+clientId);
      clientSecret = paramObject.clientSecret;
      //alert('clientId'+ clientSecret);
      //consentGroupId = paramObject.consentGroupId;
      customerId = paramObject.customerId;
      //alert('clientId'+ customerId);
      //await dispatch(consentByCustomerId())
      await dispatch(saveParamsToStateHistory(customerId, clientId, clientSecret));
      await dispatch(consentByCustomerId())
    } catch (err) {
      console.log("error:----------------------------------------> " + err.message);
    }
  }

  //   const Revoke = async () =>{
  //   await dispatch(updateStatus(val.cid))
  // }

  useEffect(() => {
    dispatch(generateToken())
    consentHistory();
    // console.log("cid vals  " );
  }, []);


  return (
    <div className="App">

      {loader &&
        <div className="App-header">
          <BallTriangle color="#00BFFF" height={80} width={80} />
          <p className="p-2" style={{ color: '#00BFFF' }} >Please wait...</p>
        </div>}


      <div className="App bgColor">

        <Modal.Header >
          <div className="d-flex align-items-center">
            <div className="row text-center">
              <img src="/jioLogo.PNG" className="img-responsive p-2 align-self-center" style={{ width: '70px', height: '70px' }} />
            </div>
            <Modal.Title className="pr-3 pl-3" >Consent Manager</Modal.Title>
          </div>

        </Modal.Header>

        <Tabs />




        {/* <Modal.Body >
          <div >
            <h3  style={{fontSize:16,fontWeight:'bold'}}>Consent History: </h3>
              <Card border="success" className="text-center">
                <Card.Header className=" text-white bg-success">Active</Card.Header>
                <Card.Body className="">
                      <Card.Subtitle>Consent ID : 8765342534754</Card.Subtitle>
                      <Card.Text className="m-0">Data consumer : JPL</Card.Text>
                      <Card.Text className="m-0">Purpose : Customized Service</Card.Text>
                      <Card.Text className="m-0">Expiry : 11-02-2022</Card.Text>
                </Card.Body>
                <button  type="button" class="btn btn-success m-0">View Details</button>
              </Card>
          </div>
        </Modal.Body > */}

      </div>


      {/* <div className="App-header">
                <div className="d-flex flex-row bd-highlight mb-3">
                    <img  src="/jioLogo.PNG" className="img-responsive p-2" style={{width: '40px',height: '40px'}} />
                    <Modal.Title style={{color:'black'}}  >Consent Manager</Modal.Title>
                </div>
                <button type="button" onClick={click} class="btn btn-outline-info m-2 p-2">Give Consent</button>
          </div> */}


      {/* <ToastContainer 
            draggable
            theme='colored' 
        /> */}


    </div>
  );
}

export default ConsentHistory;



// const notify = () => toast.error("Something went wrong please try again later !", {
//   position: toast.POSITION.BOTTOM_RIGHT,
// });